:root {
    --primary-text-color: rgb(52, 109, 219);
    --error-text-color: rgb(219, 52, 52);
    --gray-text-color: rgb(86, 86, 86);
    --secondary-text-color: rgb(31, 31, 31);
    --active-text-color: var(--primary-text-color);
    --active-button-color: rgba(52, 109, 219, 0.4);
    --active-button-bg-hover-color: rgba(52, 109, 219, 0.16);
    --active-bg-hover-color: rgba(52, 109, 219, 0.12);
    --passive-hover-text-color: rgba(31, 31, 31, 0.88);
    --passive-text-color: rgba(31, 31, 31, 0.60);
    --passive-button-color: rgba(60, 60, 60, 0.4);
    --passive-button-bg-hover-color: rgba(31, 31, 31, 0.08);
    --passive-bg-hover-color: rgba(31, 31, 31, 0.06);
    --scrollbar-bg: rgba(170, 170, 170, 0.08);
    --scrollbar-thumb: rgba(170, 170, 170, 0.20);
    --scrollbar-thumb-hover: rgba(170, 170, 170, 0.30);
}

.main {
    flex-grow: 1;
  }

.list-unstyled {
    li {
        .group-link {
            border-radius: 0.375rem 0.375rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.375rem 1.25rem;
            text-decoration: none;
            font-size: 0.875rem;
            color: var(--passive-text-color);
            border-radius: 0.375rem;
            background-color: transparent;
            transition: background-color 0.3s, color 0.3s;
        
            &:hover,
            &.active {
                background-color: var(--passive-bg-hover-color);
                color: var(--passive-hover-text-color);
            }
        }
        &.active {
            > .group-link {
              color: var(--active-text-color);
              &:hover, &:active {
                background-color: var(--active-bg-hover-color);
              }
            }
        }
    }
} 
