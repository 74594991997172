// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';
@import '../../resources/sass/header.scss';
@import '../../resources/sass/footer.scss';
@import '../../app/Components/Employee/css/index.scss';

html {
    height: 100%;
}
body {
    font-family: 'Raleway', system-ui, arial, sans-serif;
    min-height: 100%;
    background: rgb(250,250,250);
}
.navbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.118);
    background: white;
  }
  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.118);
    background: white;
    color: rgb(110, 110, 110);
    a {
        color: rgb(110, 110, 110);
        text-decoration: none;
    }
    .footer-text {
        text-decoration: none;
    }
    .footer-black {
        background: rgb(110, 110, 110);
    }
    .circle-icon {
        color: white;
        transition: transform 0.3s ease, color 0.3s ease;
        &:hover {
            color: #0C4EC0;
            transform: scale(1.5)
        }
    }
  }
.block,
.block-no-hover {
    padding: 2rem;
    margin: 1rem;

    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.118);
    border-radius: 8px; 
  
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    
  }
  @media (max-width: 762px) {
    .block,
    .block-no-hover {
        padding: 1rem;
        margin: 0.5rem;
    }
  }
.block-only-shadow {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.118);
    border-radius: 8px; 
  
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.block:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
}
.top-menu-button,
.left-menu-button {
  height: 60px;
  text-decoration: none;
  color: rgb(110, 110, 110);
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(255, 255, 255);
  &:hover {
    background: rgba(0, 0, 0, 0.058);
  }
  &.active {
    background: rgba(14, 98, 240, 0.058);
    color: rgba(14, 98, 240);
  }
  &.active:hover {
    background: rgba(14, 98, 240, 0.12);
  }
  i {
    font-size: 25px;
  }
  
}
@media (max-width: 1200px) {
    .top-menu-button {
        span {
            display: none;
        }
    }
  }
.top-menu-button.active {
  border-bottom: 3px solid rgb(14, 98, 240);
}
.left-menu-button.active {
  border-right: 3px solid rgb(14, 98, 240);
}
.navbar-brand {
    @media (max-width: 768px) {
        max-width: 100px;
        margin-right: 10px;
    }
}
.icon-symbol-round {
    background-color: rgb(14, 98, 240);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.dropdown-menu {
    width: 300px;
}
a.nav-link.dropdown-toggle {
    &:hover {
        background: rgba(0, 0, 0, 0.058);
    }
}
.dropdown-item {
    &:hover {
        background: rgba(14, 98, 240, 0.12);
        color: rgba(14, 98, 240);
    }
}
.navbar-toggler {
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    margin: 0px;
    padding: 10px 5px 10px 5px;
    &:hover,
    &:active {
        background: rgba(0, 0, 0, 0.058);
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
.table {
    border-radius: 15px 15px 0px 0px !important;
    border: 1px solid gray !important; 
    background: white;
    thead {
        th {
            background: rgb(14, 98, 240) !important;
            color: white !important;
            border: 1px solid white !important;
        }
        tr:first-child {
            th:first-child {
                border-top-left-radius: 15px !important;
            }
            
            th:last-child {
                border-top-right-radius: 15px !important;
            }
        }
    }
}
.nav-tabs {
    .nav-item {
        .nav-link {
            color: rgb(110, 110, 110);
            &:hover {
                background: rgba(0, 0, 0, 0.058);
            }
            &.active {
                background: rgba(14, 98, 240, 0.058);
                color: rgb(14, 98, 240);
                &:hover {
                    background: rgba(14, 98, 240, 0.12);
                }
            }
        }
    }
}